import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'firewall-dialog',
    template: `
    <h1 style="font-family: Roboto,'Helvetica Neue',sans-serif;" mat-dialog-title>Something Went Wrong</h1>
    <div style="font-family: Roboto,'Helvetica Neue',sans-serif;" mat-dialog-content>
        <p>It appears that our e-commerce application is not working as expected for you.</p>
        <p>If the problem persists please contact <a href="mailto:info@ag-grid.com">AG Grid</a> for assistance.</p>
    </div>
    <div style="float: right" mat-dialog-actions>
      <button mat-button cdkFocusInitial (click)="onCloseClick()">Close</button>
    </div>
    `
})
export class FirewallDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<FirewallDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { messages: string[] }) { }

    onCloseClick(): void {
        this.dialogRef.close();
    }
}