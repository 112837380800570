import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'dialog-overview-example-dialog',
    template: `
    <h1 style="font-family: Roboto,'Helvetica Neue',sans-serif;" mat-dialog-title>Something Went Wrong</h1>
    <div style="font-family: Roboto,'Helvetica Neue',sans-serif;" mat-dialog-content>
        <div *ngFor="let message of data.messages">
            <p>{{message}}</p>
        </div>
    </div>
    <div style="float: right" mat-dialog-actions>
      <button mat-button cdkFocusInitial (click)="onCloseClick()">Close</button>
    </div>
    `
})
export class SomethingWentWrongDialog {

    constructor(
        public dialogRef: MatDialogRef<SomethingWentWrongDialog>,
        @Inject(MAT_DIALOG_DATA) public data: { messages: string[] }) { }

    onCloseClick(): void {
        this.dialogRef.close();
    }
}